<ng-container *ngIf="tyreInfo">
  <div class="tyre-top">
    <!-- <app-new-tyre-badge></app-new-tyre-badge> TODO is there a way to determine if tyre is new -->
    <app-tyre-data [tyre]="tyreInfo.getProductTyre()"></app-tyre-data>
  </div>
  <div *ngIf="tyreInfo.hasTyreParams()" class="tyre-params">
    <ng-container *ngFor="let type of TyreParamType.all(); let last = last">
      <ng-container *ngIf="TyreParam.getParamsByType(tyreInfo.getTyreParams(), type)?.length > 0">
        <app-tyre-params
          [tyreParams]="TyreParam.getParamsByType(tyreInfo.getTyreParams(), type)"
          [showSeparator]="!last"
        ></app-tyre-params>
      </ng-container>
    </ng-container>
  </div>
  <div class="tyre-details">
    <app-tyre-fuel-efficient
      *ngIf="tyreInfo.hasRollingResistance()"
      [rollingResistance]="tyreInfo.getRollingResistance()"
    ></app-tyre-fuel-efficient>
    <app-tyre-adhesion *ngIf="tyreInfo.hasAdhesion()" [adhesion]="tyreInfo.getAdhesion()"></app-tyre-adhesion>
    <app-tyre-noise-level
      *ngIf="tyreInfo.hasNoiseOrNoiseLevel()"
      [noiseLevel]="tyreInfo.getNoiseLevel()"
      [noise]="tyreInfo.getNoise()"
    ></app-tyre-noise-level>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DashboardDeposit, DashboardDepositResource } from '@data/dashboard/dashboard.model';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineItemComponent {
  get deposit(): DashboardDepositResource {
    return this._deposit;
  }
  @Input()
  set deposit(value: DashboardDepositResource) {
    this.dashboardDeposit = new DashboardDeposit(value);
    this._deposit = value;
  }

  dashboardDeposit: DashboardDeposit;
  private _deposit: DashboardDepositResource;
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

let confirmation: boolean;

@Component({
  selector: 'wchfs-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string },
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  closeDialog(): void {
    this.dialogRef.close((confirmation = false));
  }

  confirmDelete(): void {
    this.dialogRef.close((confirmation = true));
  }
}

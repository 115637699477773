import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Directive({
  selector: '[skipNavigationOnSelection]',
})
export class SkipNavigationOnSelectionDirective implements OnDestroy {
  private clickUnListener: () => void;

  constructor(private ref: ElementRef, private renderer: Renderer2, private clipboard: Clipboard) {
    this.clickUnListener = renderer.listen(ref.nativeElement as HTMLElement, 'click', (event: MouseEvent) => {
      if (this.isSelectingText()) {
        event.stopImmediatePropagation();
      }
    });
  }

  ngOnDestroy(): void {
    this.clickUnListener();
  }

  private isSelectingText(): boolean {
    return window.getSelection()?.toString().length > 0;
  }
}
